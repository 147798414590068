<template>
  <div>
    <div id="app" class="vh-100" v-if="$route.name == 'Login' || $route.name == 'Signup' || $route.name == 'Forgot'">
      <div class="container-fluid px-0"></div>

      <main id="main">
        <div class="page">
          <div class="page-inner">
            <LoginNavigation></LoginNavigation>

            <div class="page-content flex-basis-fill h-100 safari-remove-height">
              <div class="container-fluid d-flex flex-column h-100">
                <div class="
                                                                                  row
                                                                                  gutters-sm
                                                                                  flex-lg-nowrap flex-basis-fill
                                                                                  overflow-y-auto
                                                                                ">
                  <div class="col">
                    <div class="wh-bg h-100">
                      <div class="d-flex flex-column">
                        <BaseDialog v-if="$root.$children[0].showBaseModal"></BaseDialog>
                        <component v-for="(dialog, index) in dialogs" v-bind:is="dialog.identifier" :dialogData="dialog"
                          :key="index + $root.$children[0].viewComponentKey">
                        </component>
                      </div>

                      <div class="container-fluid main-padding">


                        <router-view :key="$route.path"></router-view>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer class="footer">
              <div class="container-fluid">
                <ul class="list-inline m-0">
                  <li class="list-inline-item"><a href="https://ozgservices.de/datenschutz/"
                      target="_blank">Datenschutzerklärung</a></li>
                  <li class="list-inline-item">-</li>
                  <li class="list-inline-item"><a href="https://ozgservices.de/nutzungsbedingungen/"
                      target="_blank">Lizenz- und Nutzungsbedingungen</a></li>
                  <li class="list-inline-item">-</li>
                  <li class="list-inline-item"><a href="https://ozgservices.de/impressum/" target="_blank">Impressum</a>
                  </li>
                  <li class="list-inline-item">-</li>
                  <li class="list-inline-item"><a href="https://ozgservices.de/barrierefreiheit/"
                      target="_blank">Barrierefreiheit</a></li>
                </ul>
              </div>
            </footer>
          </div>
        </div>
      </main>
    </div>
    <div v-else>
      <div v-if="compiledTemplate == null">Lade OZG Manager....</div>
      <div v-else>



        <component :is="compiledTemplate"></component>



      </div>
    </div>
  </div>
</template>
<script>
import config from "./config/config.js";
import axios from "axios";
import moment from "moment";
import router from "./router/index.js";
import Page from "./components/PageBuilder.vue";
const compiler = require("vue-template-compiler");
import Vue from "vue";
import jwt_decode from "jwt-decode";


export default {
  data: () => ({
    currentPageId: 1,
    isAdminstrationMode: false,
    showBaseModal: false,
    baseModalSize: "lg",
    baseDialogComponentName: "Component",
    baseDialogData: {},
    layouts: [],
    viewComponentKey: moment().format("X"),
    navComponentKey: moment().format("X"),
    pageIsSaving: false,
    currentYear: moment().format("Y"),
    token: null,
    message: "",
    userData: {},
    notifications: [],
    notificationCount: 0,
    afterPickFunctionGlobal: null,
    dialogTitle: "Datensatz",
    dialogs: [],
    selectedLayout: 1,
    compiledTemplate: null,
    config: config,
    expireDate: null,
    time: 0,
    communityData: {},
    rootBaseHelpLink: "",
    globalComponentData: [],
    globalComponentKeys: [],
    viewListComponentKeyDetail: moment().format("X"),
    viewListComponentKey: moment().format("X"),

    viewListComponentKeyFlex: { temp1: 0, temp2: 0, temp3: 0, temp4: 0, temp5: 0, temp6: 0, temp7: 0, temp8: 0, temp9: 0, temp10: 0 },
    translations: [],
    employees: []
  }),

  beforeCreate() {

    let self = this;

    if (
      self.$route.query.t != null &&
      self.$route.query.t != undefined &&
      self.$route.query.t != ""
    ) {
      self.token = self.$route.query.t;

      axios.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;

      self.$localforage.getItem("user_ozg").then((data) => {
        if (data && data != null && self.token != null) {
          self.userData.token = self.token;
        } else {
          self.userData = data != null ? data : {};
          self.userData.token =
            self.token != null ? self.token : self.$route.query.t;
          self.token = self.$route.query.t;
        }

        self.$localforage.setItem("user_ozg", self.userData).then((r) => {
          self.initialize();
        });
      });
    } else {
      self.$localforage.getItem("user_ozg").then((data) => {
        if (data && data != null && data.token) {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${data.token}`;
          if (data.token != null) {
            self.token = data.token;
          }
          self.userData = data;
          self.initialize();
        } else {
          if (self.token == null) {
            self.redirectToLogin();
          } else {
            self.$localforage.getItem("user_ozg").then((data) => {
              if (data && data != null) {
                self.userData.token = self.token;
              } else {
                self.userData = {};
                self.userData.token = self.token;
              }

              self.$localforage.setItem("user_ozg", self.userData).then((r) => {
                self.initialize();
              });
            });
          }
        }
      });
    }
    axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        return response;
      },
      function (error) {
        if (error.response) {
          if (
            error.response.status == "401" ||
            (error.response.status == 500 &&
              error.response.request.responseURL.includes("checkToken"))
          ) {
            if (self.$route.name != "Login" && self.$route.name != "Signup" && self.$route.name != "Forgot") {
              self.redirectToLogin(true);
            }
          }
        } else {
          console.log(error);
        }
        // Do something with response error
      }
    );
  },

  created() { },
  mounted() {
    let self = this;




    axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        return response;
      },
      function (error) {
        if (error.response) {
          if (
            error.response.status == "401" ||
            (error.response.status == 500 &&
              error.response.request.responseURL.includes("checkToken"))
          ) {
            if (self.$route.name != "Login" && self.$route.name != "Signup" && self.$route.name != "Forgot") {
              self.redirectToLogin(true);
            }
          }
        } else {
          console.log(error);
        }
      }
    );
    self.$localforage.getItem("user_ozg").then((data) => {

      if (data && data != null && data.token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
        self.token = data.token;
        self.userData = data;
        self.initialize();
      } else {
        if (self.$route.name != "Login" && self.$route.name != "Signup" && self.$route.name != "Forgot") {
          self.redirectToLogin();
        }
      }
    });
  },

  methods: {
    addDialog(data, componentName, title = "Datensatz") {
      let self = this;

      this.dialogTitle = title
      self.dialogs.push({
        identifier: "BaseCrudDialog",
        data: data,
        modalSize: data.size,
        baseDialogComponentName: componentName,
      });

    },

    initialize() {
      let self = this;




      if (self.$route.name == "Login" || self.$route.name == "Signup" || self.$route.name == "Forgot") {
        return;
      }
      else {


      }
      this.$localforage.getItem("user_ozg").then((data) => {
        if (data && data != null && data.token) {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${data.token}`;

          var decodedHeader = jwt_decode(data.token);

          self.expireDate = moment(decodedHeader.exp, "X").format(
            "DD.MM.YYYY HH:mm:ss"
          );

          var interval = 1000;
          setInterval(function () {
            var eventTime = decodedHeader.exp; // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
            var currentTime = moment().format("X"); // Timestamp - Sun, 21 Apr 2013 12:30:00 GMT

            var diffTime = eventTime - currentTime;

            var duration = moment.duration(diffTime * 1000, "milliseconds");
            let time = moment.duration(duration - interval, "milliseconds");

            self.time = moment.utc(time.as("milliseconds")).format("HH:mm:ss");

            if (time.as("minutes") == 3) {
              alert("Sie werden in 3 min ausgeloggt, Bitte speichern Sie Ihre Daten")
            }
            if (time.as("minutes") == 2) {
              alert("Sie werden in 2 min ausgeloggt, Bitte speichern Sie Ihre Daten")
            }

            if (time.as("minutes") == 1) {
              alert("Sie werden ausgeloggt")
              self.logout();
            }

          }, interval);

          axios.get(config.API_URL + "/api/layout").then((response) => {
            self.layouts = response.data;
          });

          axios.get(config.API_URL + "/api/layout/current").then((response) => {
            self.selectedLayout = response.data.layoutId;
            self.setLayout();
          });


          axios
            .get(config.API_URL + "/api/checkToken", {})
            .then((response) => {
              if (response.status == 200) {
                axios.get(config.API_URL + "/api/routes").then((response) => {
                  let navigationsItems = response.data;
                  navigationsItems.forEach((item, index) => {
                    let metaOrg = JSON.parse(item.meta);
                    let metaT = metaOrg.meta;

                    if (metaT != undefined) {
                      router.addRoute({
                        component: Page,
                        name: item.title,
                        meta: metaT,
                        path: metaOrg.path,
                      });
                    }
                  });
                });
                axios
                  .get(config.API_URL + "/api/userData", {})
                  .then((response) => {
                    if (response.status == 200) {
                      if (response.data != null) {
                        self.userData = response.data.userData;
                      }
                      self.userData.token = data.token;

                      self.communityData = response.data.communityData;
                      self.communityData.communityIdentifier = self.userData.CommunityIdentifier;
                      if (response.data.baseData) {
                        self.communityData.baseData = JSON.parse(
                          response.data.baseData.value
                        ).data;
                      }
                      if (response.data.globalConfiguration != null) {
                        self.communityData.globalConfiguration = JSON.parse(
                          response.data.globalConfiguration.value
                        ).data;

                        if (self.$root.$children[0].communityData.globalConfiguration && self.$root.$children[0].communityData.globalConfiguration.globalconfig_onlinedoku_OnlinedokuServerUrl != null && self.$root.$children[0].communityData.globalConfiguration.globalconfig_onlinedoku_OnlinedokuServerUrl != undefined) {


                          let link = self.$root.$children[0].communityData.globalConfiguration.globalconfig_onlinedoku_OnlinedokuServerUrl
                          self.rootBaseHelpLink = link
                          self.$localforage
                            .setItem("ozg_doku", link)
                            .then((r) => { });



                        }


                      }




                      window.communityData = self.communityData;
                      window.formCommunityDTO = self.communityData;

                      window.currentUser = self.userData;
                      let formCommunityDTO = {}
                      axios
                        .post(
                          config.API_URL + "/api/communityData/" + self.userData.CommunityIdentifier,
                          formCommunityDTO,
                          {
                            headers: { "Content-Type": "application/json" },
                          }
                        )
                        .then((response) => {
                          self.communityData = response.data;
                          self.communityData.communityIdentifier = self.userData.CommunityIdentifier;
                          if (response.data.baseData) {
                            self.communityData.baseData = JSON.parse(
                              response.data.baseData.value
                            ).data;
                          }
                          if (response.data.globalConfiguration != null) {
                            self.communityData.globalConfiguration = JSON.parse(
                              response.data.globalConfiguration.value
                            ).data;


                          }

                          window.communityData = self.communityData;
                          window.formCommunityDTO = self.communityData;


                        });


                      self.$localforage
                        .setItem("user_ozg", self.userData)
                        .then((r) => { });
                    }
                  });

                axios.get(config.API_URL + "/api/plugin").then((response) => {
                  let navigationsItems = response.data;
                  navigationsItems.forEach((item, index) => {
                    if (item.hasDetailView) {
                      this.addRoutesForCollection(
                        item.baseCollectionId,
                        item.detailPath,
                        item.detailPage
                      );
                    }
                  });
                });

                axios
                  .get(config.API_URL + "/api/notification")
                  .then((response) => {
                    self.notifications = response.data;
                    self.notificationCount = this.notifications.filter(
                      (n) => !n.isReaded
                    ).length;
                  });
                let groupBy = function (xs, key) {
                  return xs.reduce(function (rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                  }, {});
                };
                axios
                  .get(config.API_URL + "/api/dynamicquery/translations/")
                  .then((responseLang) => {
                    if (responseLang.status == 200) {

                      let languages = responseLang.data[0].languages

                      var objectWithGroupByName = {};
                      for (var key in languages) {
                        var name = languages[key].isoCode;



                        if (!objectWithGroupByName[name]) {
                          objectWithGroupByName[name] = [];
                        }


                        var parse = languages[key].languageObject.split(":");



                        let object = {
                          [parse[0]]: parse[1]
                        }

                        objectWithGroupByName[name][parse[0]] = parse[1]
                      }
                      let temp = objectWithGroupByName;
                      self.translations = temp;


                    }

                  });







                axios
                  .get(config.API_URL + "/api/getPagesForStructure/mainNav")
                  .then((response) => {
                    let navigationsItems = JSON.parse(response.data);
                    navigationsItems.forEach((item, index) => {
                      router.addRoute({
                        component: Page,
                        name: item.title,
                        meta: {
                          pageId: item.pageId,
                          title: item.title
                        },
                        path: "/" + item.slug.toLowerCase(),
                      });

                      if (item.Children != null && item.Children.length > 0) {
                        item.Children.forEach((itemChild, index) => {
                          router.addRoute({
                            component: Page,
                            name: itemChild.title,
                            meta: {
                              pageId: itemChild.pageId,
                              title: itemChild.title + " | " + itemChild.title
                            },
                            path:
                              "/" +
                              item.slug.toLowerCase() +
                              "/" +
                              itemChild.slug.toLowerCase(),
                          });
                        });
                      }
                    });
                  });
              } else {
                self.redirectToLogin();
              }
            })
            .catch((e) => {
              self.redirectToLogin(true);
            });
        } else {
          self.redirectToLogin();
        }
      });
    },

    resolvePath(path, obj) {
      if (path != undefined && path.indexOf('.') != -1) {
        return path.split('.').reduce(function (prev, curr) {
          return prev ? prev[curr] : null
        }, obj || self)
      } else {
        return obj[path]
      }


    },
    setLayout() {
      let self = this;
      this.$localforage.getItem("user_ozg").then((data) => {
        if (data && data != null && data.token) {
          axios
            .get(config.API_URL + "/api/layout/" + self.selectedLayout)
            .then((response) => {
              let definition = JSON.parse(response.data.definition).html;
              let style = JSON.parse(response.data.styles).css;

              var newStyle = document.createElement("style");
              newStyle.appendChild(document.createTextNode(style));

              document.head.appendChild(newStyle);

              let compSpec = {
                ...compiler.compileToFunctions(definition),
                data: () => ({
                  token: self.token,

                  userData: self.userData,
                  isAdminstrationMode: self.isAdminstrationMode,
                  notifications: self.notifications,
                  navComponentKey: self.navComponentKey,
                  showBaseModal: self.showBaseModal,
                  dialogs: self.dialogs,
                  currentYear: self.currentYear,
                  notificationCount: self.notificationCount,
                }),
              };
              this.compiledTemplate = Vue.extend(compSpec);
            });
        }
      });
    },

    redirectToLogin(force = false) {
      let self = this;
      if (self.$route.name != "Login" && self.$route.name != "Signup" && self.$route.name != "Forgot") {
        self.$localforage.getItem("user_ozg").then((data) => {
          if (
            data &&
            data != null &&
            data.token &&
            self.token != null &&
            !force
          ) {
          } else {
            let jsonData = {
              key: config.APP_KEY,
              redirect: window.location.href.replace(
                window.location.search,
                ""
              ),
            };

            axios
              .post(config.API_URL + "/api/attempt/", jsonData)
              .then((response) => {
                self.$router.push("/login/" + response.data);
              });
          }
        });
      }
    },

    logout() {
      this.$localforage.removeItem("user_ozg");
      this.$route.query.t = null;
      this.token = null;
      this.redirectToLogin();
    },
    addRoutesForCollection(collectionId, path, pageId) {
      let self = this;
      if (collectionId != null && this.isAdminstrationMode) {
        axios
          .get(config.API_URL + "/api/collection/" + collectionId)
          .then((response) => {
            if (response != undefined) {
              if (
                JSON.parse(response.data)[0].items != null &&
                JSON.parse(response.data)[0].items != undefined
              ) {
                let list = JSON.parse(response.data)[0].items.map((i) => ({
                  id: i.collectionValueId,
                  value: JSON.parse(i.value),
                }));
                list.forEach(function (item) {
                  if (item.value.title == undefined) {
                    item.value.title = item.value.headline;
                  }

                  let url =
                    item.value.urlPath != null &&
                      item.value.urlPath != undefined &&
                      item.value.urlPath != ""
                      ? item.value.urlPath
                      : self.createSlug(item.value.title);

                  let rootPath = path != null && path != "" ? path : "";
                  router.addRoute({
                    component: Page,
                    name: item.value.title,
                    meta: {
                      id: item.id,
                      pageId: pageId,
                      collectionId: collectionId,
                      title: "OZG Manager : " + item.value.title,
                    },
                    path: rootPath + "" + url,
                  });

                  let meta = JSON.stringify({
                    name: item.value.title,
                    meta: {
                      id: item.id,
                      pageId: pageId,
                      collectionId: collectionId,
                      title: "OZG Manager : " + item.value.title,
                    },
                    path: path + "" + url,
                  });

                  let dataForRoute = {
                    meta: meta,
                    title: item.value.title,
                  };
                  axios
                    .put(
                      config.API_URL + "/api/setRoute/" + item.id,
                      dataForRoute
                    )
                    .then((response) => { });
                });
              }
            }
          });
      }
    },

    replacePath(path) {
      if (path != "" && path != undefined) {
        return path.replace("~", config.API_URL);
      }
      return "";
    },

    getFilePath(file) {
      if (file.value.filePath != undefined) {
        return file.value.filePath;
      } else if (
        file.value.file != undefined &&
        file.value.file.filePath != undefined
      ) {
        return file.value.file.filePath;
      }
      return "";
    },

    getIconByMime(mime) {
      let icon = "file-earmark-text";

      switch (mime) {
        case "application/msword":
          icon = "file-earmark-word";
          break;
        case "application/pdf":
          icon = "file-earmark-pdf";
          break;
        case "application/vnd.ms-powerpoint":
          icon = "file-earmark-ppt";
          break;
        case " application/x-zip-compressed":
          icon = "file-earmark-zip";
          break;

        case "video/mp4":
          icon = "file-earmark-play";
          break;
      }

      return icon;
    },

    loadPage(page) {
      this.currentPageId = page.pageId;
      this.viewComponentKey = moment().format("X");
    },
    pushToRoute(route) {
      this.$router.push(route);
      // window.location = route;
    },

    openExternalLink(url) {
      window.open(url);
    },

    createSlug(s) {
      if (s != null && s != undefined) {
        return s
          .toString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "") //remove diacritics
          .toLowerCase()
          .replace(/\s+/g, "-") //spaces to dashes
          .replace(/&/g, "-and-") //ampersand to and
          .replace(/[^\w\-]+/g, "") //remove non-words
          .replace(/\-\-+/g, "-") //collapse multiple dashes
          .replace(/^-+/, "") //trim starting dash
          .replace(/-+$/, ""); //trim ending dash
      }
    },
    afterSaveFunctionAction(item, callback) {
      let self = this;
      let defintionData = [];

      if (item.fields != undefined) {
        if (typeof item.fields !== "object") {
          defintionData = JSON.parse(item.fields);
        } else {
          defintionData = item.fields;
        }
        if (defintionData.functions != null) {
          let afterSaveFunctions = defintionData.functions.filter(
            (f) => f.key == "afterSave"
          );

          let blobs = defintionData.fields.filter((f) => f.type == "blob");
          afterSaveFunctions.forEach(function (afterSaveFunction) {
            if (afterSaveFunction.requestType == "POST") {
              if (
                blobs != null &&
                blobs.length > 0 &&
                afterSaveFunction.storeType != null &&
                afterSaveFunction.storeType == "blob"
              ) {
                let formData = new FormData();
                let updateFile = false;
                for (var file in blobs) {
                  let blob = blobs[file];

                  if (
                    item.item[blob.key] != null &&
                    item.item[blob.key] != undefined &&
                    !Array.isArray(item.item[blob.key])
                  ) {
                    if (item.item[blob.key].name != undefined) {
                      updateFile = true;

                    }
                    formData.append(
                      "attachment_" + blob.key,
                      item.item[blob.key],
                      item.item[blob.key].name
                    );
                  }
                }
                formData.append("baseData", JSON.stringify(item.item));
                if (updateFile) {
                  axios
                    .post(
                      config.API_URL + "/api/" + afterSaveFunction.controller,
                      formData
                    )
                    .then((response) => {
                      if (callback) {
                        callback();
                      } else {
                      }
                    });

                } else {
                  axios
                    .post(
                      config.API_URL + "/api/" + afterSaveFunction.controller,
                      formData
                    )
                    .then((response) => {
                      if (callback) {
                        callback();
                      } else {
                      }
                    });
                }
              }
            }
            else if (afterSaveFunction.requestType == "GET") {
            }
          });
        } else {
          if (callback) {
            callback();
          } else {
          }
        }
      }
    },

    formatDate(date, format = "DD.MM.YYYY") {
      return moment(date).format(format);
    },

    formatTime(date, format = "HH:mm") {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format(format);
    },
    addObjectDataValue(dataObject) {
      let self = this;
      axios
        .get(
          config.API_URL +
          "/api/DataObjectDef/" +
          dataObject.setting.dataObjectId
        )
        .then((responseValue) => {
          let defItem = responseValue.data;
          axios
            .get(
              config.API_URL +
              "/api/dataObject/" +
              dataObject.setting.dataObjectId
            )
            .then((response) => {
              let list = [];

              if (JSON.parse(response.data)[0].items != null) {
                list = JSON.parse(response.data)[0].items.map((obj) => ({
                  dataObjectValueId: obj.dataObjectValueId,
                  value: JSON.parse(obj.value),
                }));
              }
              let fields = JSON.parse(
                JSON.parse(response.data)[0].fields
              ).fields;

              this.$root.$children[0].showBaseModal = true;

              this.$root.$children[0].baseDialogComponentName =
                "DynamicCrudDialog";
              this.$root.$children[0].baseDialogData = {};

              this.$root.$children[0].baseDialogData.item = {};

              this.$root.$children[0].baseDialogData.fields = fields;
              this.$root.$children[0].baseDialogData.saveFunction =
                function () {
                  self.saveDataObject(
                    self.$root.$children[0].baseDialogData.item,
                    dataObject.setting.dataObjectId
                  );
                };

              this.$root.$children[0].baseDialogData.Key = moment().format("X");
            });
        });
    },

    editObjectDataValue(dataObject) {
      let self = this;
      axios
        .get(
          config.API_URL +
          "/api/DataObjectDef/" +
          dataObject.setting.dataObjectId
        )
        .then((responseValue) => {
          let defItem = responseValue.data;
          axios
            .get(
              config.API_URL +
              "/api/dataObject/" +
              dataObject.setting.dataObjectId
            )
            .then((response) => {
              let list = [];

              if (JSON.parse(response.data)[0].items != null) {
                list = JSON.parse(response.data)[0].items.map((obj) => ({
                  dataObjectValueId: obj.dataObjectValueId,
                  value: JSON.parse(obj.value),
                }));
              }
              let fields = JSON.parse(
                JSON.parse(response.data)[0].fields
              ).fields;

              this.$root.$children[0].showBaseModal = true;

              this.$root.$children[0].baseDialogComponentName =
                "DynamicCrudDialog";
              this.$root.$children[0].baseDialogData = {};

              this.$root.$children[0].baseDialogData.item =
                dataObject.poll.itemValue.value;

              this.$root.$children[0].baseDialogData.fields = fields;
              this.$root.$children[0].baseDialogData.saveFunction =
                function () {
                  self.saveDataObject(
                    self.$root.$children[0].baseDialogData.item,
                    dataObject.setting.dataObjectId
                  );
                };

              this.$root.$children[0].baseDialogData.Key = moment().format("X");
            });
        });
    },

    saveDataObject(item, dataObjectId) {
      let self = this;
      item.dataObjectId = dataObjectId;
      item.value = JSON.stringify(item);

      if (
        item.dataObjectValueId != null &&
        item.dataObjectValueId != undefined &&
        item.dataObjectValueId != -1
      ) {
        axios
          .put(
            config.API_URL + "/api/dataObjectItem/" + item.dataObjectValueId,
            item
          )
          .then((response) => {
            item.dataObjectValueId = response.data.dataObjectValueId;
            self.$root.$children[0].showBaseModal = false;
            self.$root.$children[0].afterSaveFunctionAction(item);
          });
      } else {
        axios
          .post(config.API_URL + "/api/dataObjectItem/", item)
          .then((response) => {
            self.$root.$children[0].showBaseModal = false;
            item.dataObjectValueId = response.data.dataObjectValueId;
            self.$root.$children[0].afterSaveFunctionAction(item);
          });
      }
    },
    addSingleCollectionItem(collectionItem, callback) {
      let self = this;
      let collectionId = collectionItem.pluginData.setting.collectionId
        ? collectionItem.pluginData.setting.collectionId
        : collectionItem.pluginData.setting.collection;
      axios
        .get(config.API_URL + "/api/CollectionDef/" + collectionId)
        .then((response) => {
          axios
            .get(config.API_URL + "/api/plugin/" + collectionItem.pluginData.id)
            .then((responseValue) => {
              let defaults = null;

              if (
                responseValue != undefined &&
                responseValue.data.defaults != null
              ) {
                defaults = JSON.parse(responseValue.data.defaults).defaults;
              }

              let item = collectionItem.pluginData.setting;

              let data = {};


              if (defaults != null && defaults != undefined) {


                defaults.forEach(function (defaultItem) {
                  data[defaultItem.key] = defaultItem.value;
                });
              }

              data.hidden = collectionItem.hidden
                ? collectionItem.hidden
                : true;
              data.showFrom = null;

              data.showUntil = null;
              data.formioFormId = response.data.formFormId;

              data.item = item;
              data.item.communityId = [collectionItem.communityId]
              data.fields = response.data.definition;
              data.functions = [];
              data.restrictionGroups = null;
              data.size = "lg";
              data.saveFunction = function (callbackGlobal) {
                if (callback != undefined) {
                  self.saveCollectionItem(data, callback);
                } else {
                  self.saveCollectionItem(data);
                }

                if (callbackGlobal) {
                  callbackGlobal();
                }
              };

              self.$root.$children[0].addDialog(data, "DynamicCrudDialog", "Datensatz hinzufügen");
              if (callback) {
                callback();
              }
            });
        })
        .catch((e) => { });

      self.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    editSingleCollectionItem(collectionItem, callback) {
      let self = this;
      let collectionId = collectionItem.value.collectionId
        ? collectionItem.value.collectionId
        : collectionItem.value.collection;
      axios
        .get(config.API_URL + "/api/CollectionDef/" + collectionId)
        .then((response) => {
          let item = collectionItem.value;
          let fullItems = collectionItem.fullItems;
          item.collectionValueId = collectionItem.id;
          let data = {};
          data.hidden = collectionItem.hidden;
          data.formioFormId = response.data.formFormId;
          data.showFrom = collectionItem.showFrom;
          if (
            collectionItem.restrictionGroups != null &&
            collectionItem.restrictionGroups != ""
          ) {
            data.restrictionGroups = collectionItem.restrictionGroups;
          }
          data.showUntil = collectionItem.showUntil;

          data.item = item;
          data.fullItems = fullItems;
          data.fields = response.data.definition;
          data.functions = [];
          data.size = "xl";
          data.saveFunction = function (callbackGlobal) {
            if (callback != undefined) {
              self.saveCollectionItem(data, callback);
            } else {
              self.saveCollectionItem(data);
            }

            if (callbackGlobal) {
              callbackGlobal();
            }
          };

          self.$root.$children[0].addDialog(data, "DynamicCrudDialog", "Datensatz editieren");
        })
        .catch((e) => { });

      self.$root.$children[0].baseDialogData.Key = moment().format("X");
    },


    editForm(collectionItem, callback) {
      let self = this;
      let collectionId = collectionItem.value.collectionId
        ? collectionItem.value.collectionId
        : collectionItem.value.collection;
      axios
        .get(config.API_URL + "/api/CollectionDef/" + collectionId)
        .then((response) => {
          let item = collectionItem.value;
          let fullItems = collectionItem.fullItems;
          item.formId = collectionItem.id;
          let data = {};
          data.hidden = collectionItem.hidden;
          data.formioFormId = response.data.formFormId;
          data.showFrom = collectionItem.showFrom;
          if (
            collectionItem.restrictionGroups != null &&
            collectionItem.restrictionGroups != ""
          ) {
            data.restrictionGroups = collectionItem.restrictionGroups;
          }
          data.showUntil = collectionItem.showUntil;

          data.item = item;
          data.fullItems = fullItems;
          data.fields = response.data.definition;
          data.functions = [];
          data.size = "xl";
          data.saveFunction = function (callbackGlobal) {
            if (callback != undefined) {
              self.saveForm(data, callback);
            } else {
              self.saveForm(data);
            }

            if (callbackGlobal) {
              callbackGlobal();
            }
          };

          self.$root.$children[0].addDialog(data, "DynamicCrudDialog", "Datensatz editieren");
        })
        .catch((e) => { });

      self.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    deleteForm(item, callback) {
      var deleteItem = confirm("Wirklich löschen?");

      if (deleteItem) {
        axios
          .delete(config.API_URL + "/api/form/" + item.id)
          .then((response) => {
            this.viewComponentKey = moment().format("X");
            if (callback) {
              callback();
            }
          });
      }
    },
    deleteCollectionItem(item, callback, confirmText = "Wirklich löschen?") {
      var deleteItem = confirm(confirmText);

      if (deleteItem) {
        axios
          .delete(config.API_URL + "/api/collectionItem/" + item.id)
          .then((response) => {
            this.viewComponentKey = moment().format("X");
            if (callback) {
              callback();
            }
          });
      }
    },

    copyCollectionItem(item, callback) {
      var deleteItem = confirm("Wirklich kopieren?");

      let copyItem = {};
      copyItem.value = item.value;
      copyItem.collectionId = item.collectionId;

      if (deleteItem) {
        axios
          .put(
            config.API_URL + "/api/collectionItemCopy/" + item.collectionValueId
          )
          .then((response) => {
            this.viewComponentKey = moment().format("X");
            if (callback) {
              callback();
            }
          });
      }
    },



    copyForm(item, callback) {
      var deleteItem = confirm("Wirklich kopieren?");

      let copyItem = {};
      copyItem.value = item.value;
      copyItem.collectionId = item.collectionId;

      if (deleteItem) {
        axios
          .put(
            config.API_URL + "/api/FormCopy/" + item.formId
          )
          .then((response) => {
            this.viewComponentKey = moment().format("X");
            if (callback) {
              callback();
            }
          });
      }
    },

    addUserInteraction(action, callback) {
      axios
        .post(config.API_URL + "/api/addUserInteraction/", action)
        .then((response) => {
          this.viewComponentKey = moment().format("X");
          if (callback) {
            callback();
          }
        });
    },

    showMessage(title, text, variant = "success") {
      this.$bvToast.toast(title, {
        title: text,
        variant: variant,
        solid: true,
      });
    },

    saveCollectionItem(baseData, callback) {
      let self = this;

      let item = baseData.item;
      let data = {};
      data.value = JSON.stringify(JSON.parse(JSON.stringify(item)));
      data.hidden = baseData.hidden;
      data.showFrom = baseData.showFrom;
      data.showUntil = baseData.showUntil;
      data.sortOrder = baseData.sortOrder;

      if (
        baseData.restrictionGroups != null &&
        baseData.restrictionGroups != ""
      ) {
        data.restrictionGroups = JSON.stringify(
          baseData.restrictionGroups.map((rg) => ({
            adGroupId: rg.adGroupId,
            adGroupName: rg.adGroupName,
          }))
        );
      }

      let collectionId = item.collectionId
        ? item.collectionId
        : item.collection;

      data.collectionId = collectionId;

      data.collectionValueId = item.collectionValueId;
      if (
        item.collectionValueId != null &&
        item.collectionValueId != undefined &&
        item.collectionValueId != -1
      ) {
        axios
          .put(
            config.API_URL + "/api/CollectionItem/" + item.collectionValueId,
            data
          )
          .then((response) => {
            let afterSaveData = {
              ...data,
              ...baseData,
            };
            this.afterSaveFunctionAction(afterSaveData);

            if (callback) {
              callback();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        axios
          .post(config.API_URL + "/api/CollectionItem/", data)
          .then((response) => {
            baseData.item.collectionValueId = response.data.collectionValueId;

            let afterSaveData = {
              ...response.data,
              ...baseData,
            };

            this.afterSaveFunctionAction(afterSaveData);
            if (callback) {
              callback();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },


    saveForm(baseData, callback) {
      let self = this;

      let item = baseData.item;
      let data = {};
      data.value = JSON.stringify(JSON.parse(JSON.stringify(item)));
      data.hidden = baseData.hidden;
      data.showFrom = baseData.showFrom;
      data.showUntil = baseData.showUntil;
      data.sortOrder = baseData.sortOrder;

      if (
        baseData.restrictionGroups != null &&
        baseData.restrictionGroups != ""
      ) {
        data.restrictionGroups = JSON.stringify(
          baseData.restrictionGroups.map((rg) => ({
            adGroupId: rg.adGroupId,
            adGroupName: rg.adGroupName,
          }))
        );
      }

      let collectionId = item.collectionId
        ? item.collectionId
        : item.collection;

      data.collectionId = collectionId;

      data.formId = item.formId;
      if (
        item.formId != null &&
        item.formId != undefined &&
        item.formId != -1
      ) {
        axios
          .put(
            config.API_URL + "/api/form/" + item.formId,
            data
          )
          .then((response) => {
            let afterSaveData = {
              ...data,
              ...baseData,
            };
            this.afterSaveFunctionAction(afterSaveData);

            if (callback) {
              callback();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        axios
          .post(config.API_URL + "/api/form/", data)
          .then((response) => {
            baseData.item.formId = response.data.formId;

            let afterSaveData = {
              ...response.data,
              ...baseData,
            };

            this.afterSaveFunctionAction(afterSaveData);
            if (callback) {
              callback();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    deepFilter(object, searchField, value) {
      if (object.hasOwnProperty(searchField) && object[searchField] == value) {
        return object;
      }
      if (
        typeof object[Object.keys(object)[i]] == "object" &&
        object[Object.keys(object)[i]] != null
      ) {
        var childObject = object[Object.keys(object)[i]];

        var o = this.deepFilter(
          object[Object.keys(object)[i]],
          searchField,
          value
        );
        if (o != null) return o;
      }

      return null;
    },

    list_to_tree(arr) {
      let arrMap = new Map(arr.map((item) => [item.contentCommentId, item]));

      let tree = [];

      for (let i = 0; i < arr.length; i++) {
        let item = arr[i];

        if (
          item.parentId !== "0" &&
          item.parentId != 0 &&
          item.parentId != null
        ) {
          let parentItem = arrMap.get(item.parentId);

          if (parentItem) {
            let { children } = parentItem;

            if (children) {
              parentItem.children.push(item);
            } else {
              parentItem.children = [item];
            }
          }
        } else {
          tree.push(item);
        }
      }
      return tree;
    },

    setReaded(item) {
      let self = this;
      axios
        .get(config.API_URL + "/api/notificationReaded/" + item.notificationId)
        .then((response) => {
          axios.get(config.API_URL + "/api/notification").then((response) => {
            self.notifications = response.data;
            self.notificationCount = this.notifications.filter(
              (n) => !n.isReaded
            ).length;
          });
        });
    },

    getParseData(jsonString) {
      if (jsonString != undefined) {
        return JSON.parse(jsonString);
      } else {
        return [];
      }
    },


    dynamicAPI(API_PATH, API_TYPE, API_DATA, callback) {


      if (API_TYPE == "POST") {
        axios.post(config.API_URL + API_PATH, API_DATA)
          .then((response) => {
            if (callback) {
              callback();
              this.showMessage(
                "Gespeichert",
                "Daten gespeichert",
                "success"
              );
              if (refresh) {
                location.reload();
              }
            }
            else {
              this.showMessage(
                "Gespeichert",
                "Daten gespeichert",
                "success"
              );

              location.reload();

            }
          });
      }

      else {
        axios.get(config.API_URL + API_PATH, API_DATA)
          .then((response) => {
            if (callback) {
              callback();
              this.showMessage(
                "Gespeichert",
                "Daten gespeichert",
                "success"
              );
              if (refresh) {
                location.reload();
              }
            }
            else {
              this.showMessage(
                "Gespeichert",
                "Daten gespeichert",
                "success"
              );

              location.reload();

            }
          });
      }
    }
    ,
    dynamicQuery(dataObject, callback, refresh) {
      let query = {};
      query.type = dataObject.type;
      if (query.type == "execute") {
        query.query = "" + dataObject.queryName + " " + dataObject.values.join() + "";

      } else {
        query.query = "" + dataObject.queryName + "(" + dataObject.values.join() + ")";

      }

      axios.post(window.GLOBALCONFIG.API_URL + "/api/dynamicQuery/", query)
        .then((response) => {
          if (callback) {
            callback(response);
            this.showMessage(
              "Gespeichert",
              "Daten gespeichert",
              "success"
            );
            if (refresh) {
              location.reload();
            }
          }
        })

    }
    , addNewMainPage() {
      this.$root.$children[0].showBaseModal = true;
      this.$root.$children[0].baseDialogComponentName = "PageDialog";

      this.$root.$children[0].baseDialogData = {};
      this.$root.$children[0].baseDialogData.structureId = 1;
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    addNewSubPage(parentId) {
      this.$root.$children[0].showBaseModal = true;
      this.$root.$children[0].baseDialogComponentName = "PageDialog";

      this.$root.$children[0].baseDialogData = {};
      this.$root.$children[0].baseDialogData.structureId = 1;
      this.$root.$children[0].baseDialogData.parentId = parentId;
      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    editPage(page) {
      this.$root.$children[0].showBaseModal = true;
      this.$root.$children[0].baseDialogComponentName = "PageDialog";

      this.$root.$children[0].baseDialogData = page;

      this.$root.$children[0].baseDialogData.Key = moment().format("X");
    },

    deletePage(page) {
      let deletePage = confirm("Seite löschen?");

      if (deletePage) {
        axios
          .delete(config.API_URL + "/api/page/" + page.pageId)
          .then((response) => {
            this.$root.$children[0].navComponentKey = moment().format("X");
          });
      }
    },

    testAction() {

      alert("test");
    }


  },
};
</script>
<style></style>
<style>
@import "./assets/Style/base.css";
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  font-family: "Raleway-medium";
  font-size: 14px;
  line-height: 1.5;
  color: #303030;
  background: #efefef;
  min-width: 980px;
}

body a,
body a:visited {
  color: #000;
}

.header {
  height: 60px;
  background: #fff;
  -webkit-box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  margin-bottom: 14px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

/*# sourceMappingURL=default.css.map */

.borderAdministrationMode {
  border: 1px dashed;
}

.btn.ozg-button-collapse,
.btn.ozg-button-collapse:focus,
.btn.ozg-button-collapse:active,
.btn.ozg-button-collapse:hover {
  border-radius: 0px;
  background-color: #f7a600;
  color: #000;
  width: 100%;
  border: 0px;
  text-align: left;
}

.btn.ozg-button-collapse.subCollapse,
.collapse .ozg-button-collapse {
  background-color: #fff;
  border: 4px solid #f7a600;
}

.noBorder {
  border: 0px !important;
}

.quillWrapper {
  border: 1px solid #aaa;
  margin-bottom: 7px;
  margin-top: 7px;
}

.ql-editor {
  background-color: #fff;
}

.modal-content {
  border: 0px !important;
  border-radius: 0 !important;
}

.modal-body .form-control,
.modal-body .custom-select {
  border: 0px;
  border-bottom: 1px solid #f7a600;
  border-radius: 0px;
}

.modal-body .card {
  border: 0px;
  border: 1px dashed #f7a600;
  border-radius: 0px;
}

.col-form-label {
  color: #143b23;
  font-weight: 600;
}

.text-gray-100 {
  color: #f8f9fc !important;
}

.text-gray-200 {
  color: #eaecf4 !important;
}

.text-gray-300 {
  color: #dddfeb !important;
}

.text-gray-400 {
  color: #d1d3e2 !important;
}

.text-gray-500 {
  color: #b7b9cc !important;
}

.text-gray-600 {
  color: #858796 !important;
}

.text-gray-700 {
  color: #6e707e !important;
}

.text-gray-800 {
  color: #5a5c69 !important;
}

.text-gray-900 {
  color: #3a3b45 !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold-600 {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

img {
  max-width: 100%;
}

.ozg-link,
.ozg-link:hover {
  color: #f7a600;
}

.inactiveItem {
  border: 1px #f00 dashed;
  opacity: 0.25;
}

.file_manager .file a:hover .hover,
.file_manager .file .file-name small {
  display: block;
}

.file_manager .file {
  padding: 0 !important;
}

.file_manager .file .icon {
  text-align: center;
}

.file_manager .file {
  position: relative;
  border-radius: 0.55rem;
  overflow: hidden;
}

.file_manager .file .image,
.file_manager .file .icon {
  max-height: 180px;
  overflow: hidden;
  background-size: cover;
  background-position: top;
}

.file_manager .file .hover {
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
  transition: all 0.2s ease-in-out;
}

.file_manager .file a:hover .hover {
  transition: all 0.2s ease-in-out;
}

.file_manager .file .icon {
  padding: 15px 10px;
  display: table;
  width: 100%;
}

.file_manager .file .icon i {
  display: table-cell;
  font-size: 30px;
  vertical-align: middle;
  color: #777;
  line-height: 100px;
}

.file_manager .file .file-name {
  padding: 10px;
  border-top: 1px solid #f7f7f7;
}

.file_manager .file .file-name small .date {
  float: right;
}

.folder {
  padding: 20px;
  display: block;
  color: #777;
}

@media only screen and (max-width: 992px) {
  .file_manager .nav-tabs {
    padding-left: 0;
    padding-right: 0;
  }

  .file_manager .nav-tabs .nav-item {
    display: inline-block;
  }
}

a:hover {
  text-decoration: none;
}

.file_manager li,
.file_manager>ul,
.folder,
.folder li {
  list-style: none !important;
}

#main {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

#main .page {
  height: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.page .page-inner {
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.page .page-inner .page-content {
  overflow: auto;
}

.wh-bg {
  background-color: #fff !important;
}

.flex-basis-fill {
  flex-basis: 100% !important;
}


.badge-primary {
  background-color: #d62029 !important;
}

.badge-md {
  font-size: 14px;
  font-weight: normal;
  padding: 5px 10px;
}

.logo {
  font-size: inherit;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  color: #0e131a;
  font-size: 30px;
}

.logo span {
  width: 3px;
  display: block;
  height: 23px;
  background-color: #e10308;
  margin: 0 5px;
  overflow: hidden;
  position: relative;
}

.logo span:after,
.logo span:before {
  content: "";
  height: 33.33333%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
}

.logo span:after {
  top: auto;
  bottom: 0;
  background-color: #fecf09;
}

.footer {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  text-align: center;
}

.no-message .buttonMessageContainer .help-block {
  display: none !important;
  ;
}
</style>
