import { Formio } from "formiojs";
import Component from "formiojs/components/_classes/component/Component";


const SelectComponent = Formio.Components.components.select;
const editForm = Formio.Components.components.nested.editForm;

let dataComp = {}
const values = []
let table = null;
let compKey = "";

export default class CustomDataSelectComponent extends SelectComponent {
    /**
     * This is the default schema of your custom component. It will "derive"
     * from the base class "schema" and extend it with its default JSON schema
     * properties. The most important are "type" which will be your component
     * type when defining new components.
     *
     * @param extend - This allows classes deriving from this component to
     *                 override the schema of the overridden class.
     */
    static schema(...extend) {
        return SelectComponent.schema({
                type: "customDataSelect",
                label: "CustomDataSelectComponent",
                key: "customDataSelect",

            },
            ...extend
        );
    }

    /**
     * This is the Form Builder information on how this component should show
     * up within the form builder. The "title" is the label that will be given
     * to the button to drag-and-drop on the buidler. The "icon" is the font awesome
     * icon that will show next to it, the "group" is the component group where
     * this component will show up, and the weight is the position within that
     * group where it will be shown. The "schema" field is used as the default
     * JSON schema of the component when it is dragged onto the form.
     */
    static get builderInfo() {
        return {
            title: "CustomDataSelectComponent",
            icon: "html",
            group: "basic",
            documentation: "/userguide/#textfield",
            weight: 0,
            schema: CustomDataSelectComponent.schema(),
        };
    }

    /**
     * Called when the component has been instantiated. This is useful to define
     * default instance variable values.
     *
     * @param component - The JSON representation of the component created.
     * @param options - The global options for the renderer
     * @param data - The contextual data object (model) used for this component.
     */
    constructor(component, options, data) {
        super(component, options, data);
    }

    /**
     * Called immediately after the component has been instantiated to initialize
     * the component.
     */
    init() {
        window.isLoaded = false;




        super.init();
    }


    /**
     * This method is used to render a component as an HTML string. This method uses
     * the template system (see Form Templates documentation) to take a template
     * and then render this as an HTML string.
     *
     * @param content - Important for nested components that receive the "contents"
     *                  of their children as an HTML string that should be injected
     *                  in the {{ content }} token of the template.
     *
     * @return - An HTML string of this component.
     */
    render(_content) {

        let self = this;
        let _componentApiKey = this.component.apiKey
        let _component = this.component;
        compKey = this.component.key

        this.component.placeholder = (this.component.placeholder != null && this.component.placeholder != '') ? this.component.placeholder : this.component.label + " wählen"

        let query = {}
        query.query = this.component.dynamicQuery

        window.GLOBALAXIOS
            .post(window.GLOBALCONFIG.API_URL + "/api/dynamicQuery/", query)
            .then((response) => {
                let _collectionBaseData = JSON.parse(response.data);





                let collectionData = _collectionBaseData.map(function(e) {

                    let _selected = false;



                    let _label = "";
                    let labelData = [];
                    if (_component.data.labelFields) {
                        _component.data.labelFields.forEach(function(field) {

                            labelData.push(e[field.field])

                        })
                        _label = labelData.join(" | ");

                        return ({ "label": _label, value: e })
                    } else {
                        return ({ "label": "N/A", value: e })
                    }
                });


                self.component.data.values = collectionData
            });

        return super.render();




    }




    attach(element) {

        this.loadRefs(element, {

            addNewRef: 'single',



        });




        const superAttach = super.attach(element);
        return superAttach;






    }

    detach() {

        return super.detach();
    }

    /**
     * Called when the component has been completely "destroyed" or removed form the
     * renderer.
     *
     * @return - A Promise that resolves when this component is done being destroyed.
     */
    destroy() {

        return super.destroy();
    }


    /**
     * A very useful method that will take the values being passed into this component
     * and convert them into the "standard" or normalized value. For exmample, this
     * could be used to convert a string into a boolean, or even a Date type.
     *
     * @param value - The value that is being passed into the "setValueAt" method to normalize.
     * @param flags - Change propogation flags that are being used to control behavior of the
     *                change proogation logic.
     *
     * @return - The "normalized" value of this component.
     */
    normalizeValue(value, flags = {}) {
        return super.normalizeValue(value, flags);
    }

    /**
     * Returns the value of the "view" data for this component.
     *
     * @return - The value for this whole component.
     */
    getValue() {
        return super.getValue();
    }

    /**
     * Much like "getValue", but this handles retrieving the value of a single index
     * when the "multiple" flag is used within the component (which allows them to add
     * multiple values). This turns a single value into an array of values, and this
     * method provides access to a certain index value.
     *
     * @param index - The index within the array of values (from the multiple flag) 
     *                that is getting fetched.
     *
     * @return - The view data of this index.
     */
    getValueAt(index) {
        return super.getValueAt(index);
    }

    /**
     * Sets the value of both the data and view of the component (such as setting the
     * <input> value to the correct value of the data. This is most commonly used
     * externally to set the value and also see that value show up in the view of the
     * component. If you wish to only set the data of the component, like when you are
     * responding to an HMTL input event, then updateValue should be used instead since
     * it only sets the data value of the component and not the view. 
     *
     * @param value - The value that is being set for this component's data and view.
     * @param flags - Change propogation flags that are being used to control behavior of the
     *                change proogation logic.
     *
     * @return - Boolean indicating if the setValue changed the value or not.
     */
    setValue(value, flags = {}) {
        return super.setValue(value, flags);
    }

    /**
     * Sets the value for only this index of the component. This is useful when you have
     * the "multiple" flag set for this component and only wish to tell this component
     * how the value should be set on a per-row basis.
     *
     * @param index - The index within the value array that is being set.
     * @param value - The value at this index that is being set.
     * @param flags - Change propogation flags that are being used to control behavior of the
     *                change proogation logic.
     *
     * @return - Boolean indiciating if the setValue at this index was changed.
     */
    setValueAt(index, value, flags = {}) {
        return super.setValueAt(index, value, flags);
    }





}


CustomDataSelectComponent.editForm = function() {
    return {
        components: [

            {
                key: 'components',
                type: 'tabs',
                input: true,
                label: 'Tabs',
                weight: 50,
                reorder: true,
                components: [{


                        key: 'customEditGridData',
                        label: 'Data',
                        components: [



                            {
                                type: 'textfield',
                                key: 'key',
                                label: 'API-Key',
                                validate: {
                                    required: true,
                                },
                            },

                            {
                                type: 'textfield',
                                key: 'dynamicQuery',
                                label: 'dynamicQuery',
                                validate: {
                                    required: true,
                                },
                            },
                            {
                                type: 'datagrid',
                                label: 'LabelFields',
                                key: 'data.labelFields',
                                weight: 5,
                                reorder: true,
                                defaultValue: [{ field: '' }],
                                components: [{
                                        label: 'Feld',
                                        key: 'field',
                                        input: true,
                                        width: 200,
                                        type: 'textfield',
                                    },

                                ],
                                conditional: {
                                    json: { '===': [{ var: 'data.dataSrc' }, 'values'] },
                                },
                            },
                            {
                                type: 'textfield',
                                key: 'selectedMapping',
                                label: 'Feld für Selected'
                            },
                            {
                                type: 'textfield',
                                key: 'label',
                                label: 'Label'
                            },
                            {
                                type: 'textfield',
                                key: 'placeholder',
                                label: 'Placeholder'
                            },
                            {
                                type: 'checkbox',
                                key: 'hideLabel',
                                label: 'Hide Label'
                            },
                        ]
                    }

                ]
            },

        ]
    };
}




Formio.Components.addComponent('customDataSelect', CustomDataSelectComponent);